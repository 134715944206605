import React from "react"
import BaseLink from "../components/base-link"
import Seo from "../components/seo"
import Video404 from "../assets/videos/ilustracion-voltio-error-404.mp4"

const forOuFor = () => {
  return (
    <main className="flex h-screen w-full flex-col items-center justify-center px-4">
      <Seo />
      <div className="flex w-full flex-shrink-0 flex-col items-center justify-center gap-4 bg-white">
        <header className="relative mx-auto h-72 w-full max-w-2xl overflow-hidden">
          <video
            controls
            autoPlay
            muted
            alt="video error 404"
            loop
            className="absolute left-0 top-0 z-10 block h-full w-full object-cover"
          >
            <source src={Video404} type="video/mp4" />
            Su navegador no soporta vídeos HTML5.
          </video>
        </header>
        <div className="flex flex-col">
          <h1 className="text-center font-bold text-brand-primary">
            <span className="block text-2xl">Error</span>
            <span className="block text-8xl">404</span>
          </h1>
          <div className="my-8 mt-2 text-center text-xl font-semibold">
            <p>!Ups, parece que ha habido un error!</p>
            <p>Esta página no está disponible</p>
          </div>
        </div>
        <BaseLink to="/" className="md:2/5 z-20 w-full flex-shrink-0 lg:w-1/5">
          Vuelve a la Home
        </BaseLink>
      </div>
    </main>
  )
}

export default forOuFor
